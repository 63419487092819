@tailwind base;
@tailwind components;
@tailwind utilities;

iframe#webpack-dev-server-client-overlay{display:none!important}


body {
  margin: 0;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Noto Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track-piece {
  background-color: #f3f3f3;
}
::-webkit-scrollbar-thumb {
  background-color: #989898;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3a3a3a;
}

.site-px {
  @apply px-6 md:px-8;
}

h1 {
  @apply font-extrabold uppercase text-2xl sm:text-3xl text-oszsz-blue-dark;
}

h2 {
  /* @apply font-bold text-xl text-sznkki-gray-dark; */
}

p {
  /*@apply mb-4;*/
}

.lead {
  @apply text-base font-bold leading-7;
}

.content {
  @apply text-base leading-7;
}

.content a {
   @apply text-oszsz-blue transition hover:text-oszsz-blue-dark;
}

.content table {
  /* @apply min-w-[280px]; */
}

.content ul {
  @apply leading-7 list-disc list-inside;
}

.button {
  @apply bg-oszsz-blue hover:bg-oszsz-blue-dark transition flex items-center justify-center py-4 text-sm text-white font-bold uppercase rounded-lg;
}
